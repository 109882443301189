<template>
    <div class="page">
        <div class="login-sec">
            <img src="../assets/images/login_01.png" class="smlogo" alt="" />
            <div class="box">
                <h6 class="t-tit">欢迎登录</h6>
                <el-form ref="form" :model="loginForm" :rules="loginRules">
                    <el-form-item label="账号（手机号）：" prop="phone">
                        <el-input v-model="loginForm.phone">
                            <img src="../assets/images/login_03.png" class="ico" alt="" slot="prefix" />
                        </el-input>
                    </el-form-item>
                    <el-form-item label="密码：" prop="pwd">
                        <el-input v-model="loginForm.pwd" show-password>
                            <img src="../assets/images/login_04.png" class="ico" alt="" slot="prefix" />
                        </el-input>
                    </el-form-item>
                    <div class="sm">
                        <el-checkbox v-model="rememberChecked">记住密码</el-checkbox>
                        <router-link to="" class="forget underline" @click="forgetPop = true">忘记密码？</router-link>
                    </div>
                    <button class="submit" @click.prevent="handleLogin">登录</button>
                </el-form>
                <div class="tips">
                    <p>
                        <span>您还没有账户？</span>
                        <router-link to="" class="lk">请联系QHKT系统部或查看使用手册</router-link>
                    </p>
                    <p>
                        <span>首次使用？</span>
                        <router-link to="" class="lk">使用手册</router-link>
                    </p>
                </div>
            </div>
        </div>
        <el-dialog :visible.sync="forgetPop" width="530px">
            <div class="pop pop-psd">
                <div class="toptt">
                    <span class="backbtn">修改密码</span>
                    <i class="el-icon-close close" @click="forgetPop = false"></i>
                    <div class="dix"></div>
                </div>
                <div class="detail">
                    <el-form ref="form" :model="forgetForm" label-width="160px">
                        <el-form-item label="请输入账号：">
                            <el-input v-model="forgetForm.username" placeholder="11位手机号"></el-input>
                        </el-form-item>
                        <el-form-item label="请输入原始密码：">
                            <el-input type="password" v-model="forgetForm.password1" placeholder="请输入"></el-input>
                        </el-form-item>
                        <el-form-item label="请输入新密码：">
                            <el-input type="password" v-model="forgetForm.password2" placeholder="8位字母、数字组合"></el-input>
                        </el-form-item>
                        <el-form-item label="再次输入新密码：">
                            <el-input type="password" v-model="forgetForm.password3" placeholder="请输入"></el-input>
                        </el-form-item>
                        <div class="btngroup">
                            <button class="ann confirm">确定</button>
                            <button class="ann back" @click="forgetPop = false">返回</button>
                        </div>
                    </el-form>
                </div>
            </div>
        </el-dialog>\
        <div style="
        position: fixed;
        text-align: center;
        bottom: 0;
        margin: 0 auto;
        width: 100%;
        font-size: 14px;
        color: rgb(154, 169, 184);
      ">
            <a target="_icp" href="https://beian.miit.gov.cn/">京ICP备2021010296号</a>
            | <img src="https://www.beian.gov.cn/img/new/gongan.png" />
            <a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011502037650"
                target="_blank">京公网安备11011502037650号</a>
            &nbsp; &nbsp; Copyright ©2019-2024 ThuPower All Rights Reserved
        </div>
    </div>
</template>

<script>
import { userLogin } from "@/api/login";
import { projectList } from "@/api/project";
export default {
    data() {
        return {
            //登录表单
            loginForm: {
                phone: "",
                pwd: "",
            },
            loginRules: {
                phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
                pwd: [{ required: true, message: "请输入登录密码", trigger: "blur" }],
            },
            rememberChecked: false,
            forgetPop: false,
            forgetForm: {
                username: "",
                password1: "",
                password2: "",
                password3: "",
            },
        };
    },
    components: {},
    created() {
        const username = localStorage.getItem("username");
        const password = localStorage.getItem("password");
        if (username && password) {
            this.loginForm.phone = username;
            this.loginForm.pwd = password;
            this.rememberChecked = true;
        }
    },
    mounted() { },
    watch: {},
    methods: {
        //获取项目列表
        getProjectList() {
            let param = {
                pageNum: 1,
                pageSize: 9999999,
                tenantId: JSON.parse(sessionStorage.getItem("user")).tenantId,
            };
            projectList(param).then((res) => {
                if (res.data.code == 0) {
                    sessionStorage.setItem("projectList", JSON.stringify(res.data.rows));
                    if (res.data.rows.length > 0) {
                        sessionStorage.setItem("projectId", res.data.rows[0].id);
                        sessionStorage.setItem("projectName", res.data.rows[0].name);
                    }
                    this.$router.push("/");
                } else {
                    this.$message.warning(res.data.msg);
                }
            });
        },

        // 用户登录
        handleLogin() {
            const username = this.loginForm.phone;
            const password = this.loginForm.pwd;
            const rememberMe = this.rememberChecked;
            localStorage.setItem("username", username);
            if (rememberMe) {
                localStorage.setItem("password", password);
            } else {
                localStorage.removeItem("password"); // 如果用户没有选中记住密码选项，则清除localStorage中的密码信息
            }
            userLogin(this.loginForm).then((res) => {
                if (res.data.code == 0) {
                    let resp = res.data;
                    this.$refs["form"].validate((valid) => {
                        if (valid) {
                            this.$store.dispatch("setToken", resp.rows[0].token);
                            this.$store.dispatch("setUser", JSON.stringify(resp.rows[0]));
                            //保存用户信息
                            sessionStorage.setItem("user", JSON.stringify(resp.rows[0]));
                            //保存token信息
                            sessionStorage.setItem("userToken", resp.rows[0].token);
                            this.getProjectList();
                        } else {
                            this.$message.error(resp.msg);
                            return false;
                        }
                    });
                } else {
                    this.$message.warning(res.data.msg);
                }
            });
        },
    },
};
</script>

<style scoped>
.login-sec {
    height: 100vh;
    background: url(../assets/images/login_06.jpg) no-repeat center;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.smlogo {
    height: 43px;
    margin-bottom: 45px;
}

.box {
    background: url(../assets/images/login_02.png) no-repeat center;
    background-size: 100% 100%;
    width: 614px;
    height: 508px;
}

.t-tit {
    font-size: 24px;
    color: #fff;
    text-align: center;
    line-height: 60px;
    margin-top: 5px;
    margin-bottom: 30px;
    font-weight: bold;
}

.el-form {
    width: 300px;
    margin: 0 auto;
}

.el-form>>>.el-form-item {
    margin-bottom: 15px;
}

.el-form>>>.el-form-item__label {
    font-size: 16px;
    color: #ccd9e5;
    margin-bottom: 3px;
}

.el-form>>>.el-input__inner {
    font-size: 16px;
    height: 44px;
    line-height: 44px;
    color: #ccd9e5;
    padding-left: 45px;
}

.el-form .submit {
    width: 100%;
    height: 46px;
    border: none;
    cursor: pointer;
    background: url(../assets/images/login_05.png) no-repeat center;
    background-size: 100% 100%;
    margin-top: 30px;
    color: #fff;
}

.el-form .submit:hover {
    opacity: 0.85;
}

.el-form .sm {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.el-form .forget {
    font-size: 14px;
    color: #aa2bff;
}

.el-form>>>.el-checkbox__label {
    font-size: 14px;
    color: #ccd9e5;
}

.el-form>>>.el-checkbox__inner {
    width: 16px;
    height: 16px;
    border: 1px solid #4a4f64;
    background-color: #16192f;
}

.el-form>>>.el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #a729f1;
    border-color: #a729f1;
}

.el-form>>>.el-checkbox__inner::after {
    height: 8px;
    top: 3px;
    left: 5px;
}

.el-form .ico {
    width: 18px;
    margin: 0 15px 0 10px;
}

.el-form>>>.el-input__prefix {
    display: flex;
    align-items: center;
}

.tips {
    width: 340px;
    margin: 25px auto 0;
}

.tips p {
    font-size: 14px;
    color: #ccd9e5;
    line-height: 26px;
    display: flex;
}

.tips span {
    width: 100px;
    text-align: right;
    margin-right: 10px;
}

.tips .lk {
    color: #a729f1;
}

.tips .lk:hover {
    text-decoration: underline;
}
</style>
