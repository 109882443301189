import http from "../utils/http";
/**
 *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */
let resquest = "/apis";
// 修改密码
export function userLogin(params) {
  return http.post(`${resquest}/user/002-login`, params);
}
