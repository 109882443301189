import http from "../utils/http";
/**
 *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */
let resquest = "/apis";
// 查询项目列表
export function projectList(params) {
  return http.post(`${resquest}/cusProject/list`, params);
}

// 修改项目信息
export function editProject(params) {
    return http.post(`${resquest}/cusProject/edit`, params);
  }
